<template>
    <div>
        <v-form @submit.prevent="sendOrder" ref="refForm" lazy-validation>
            <v-card class="mb-5">
                <v-card-title>
                    ¿Cómo deseas recibir tu pedido?
                </v-card-title>
                <v-card-text>
                    <v-select
                        :items="itemStores"
                        label="Selecciona"
                        item-text="name"
                        item-value="id"
                        outlined
                        v-model="receiveOrder"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        @change="choseStore"
                    ></v-select>

                    <div v-if="tempStore">
                        <v-alert type="info" outlined>
                            <strong>Tiempo de entrega</strong> <br>
                            <span v-if="tempStore.id != 0">
                                {{ tempStore.name }}. Nos comunicaremos con usted cuando su pedido se encuentre listo para que pueda recogerlo de nuestra tienda. Muchas gracias por su compra.
                                <!-- Horarios de entrega: De lunes a sábado de 14:00 a 20:00 Hrs. (Domingos y feriados no habrá atención) -->
                            </span>
                            <span v-else>
                                Para el envió a domicilio recibirás un correo de confirmación indicando que tu pedido está listo y posteriormente una llamada de nuestro personal para coordinar la entrega.
                            </span>
                        </v-alert>
                    </div>
                </v-card-text>
            </v-card>

            <v-card class="mb-5" v-if="tempStore && tempStore.id!=0">
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Nombre completo de la persona que recibirá el pedido *"
                        outlined
                        v-model="name"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                        label="Tel/Cel de contacto *"
                        outlined
                        type="number"
                        v-model="phone"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required, rules.phone, rules.phone2]"
                    ></v-text-field>
                </v-card-text>
            </v-card>

            <v-card class="mb-5" v-if="tempStore && tempStore.id==0">
                <v-card-title>
                    Ubicación en mapa * &nbsp; <small>(Selecione su ubicación en el mapa)</small>
                </v-card-title>
                <v-card-text>
                    <google-map
                        :center="{lat: tmpLat, lng: tmpLng}"
                        :zoom="15"
                        v-if="showMap"
                        @result-click="resultClickMap"
                    ></google-map>
                </v-card-text>
            </v-card>

            <v-card v-if="tempStore && tempStore.id!=0" class="mb-5">
                <v-card-title>
                    Dirección de recojo
                </v-card-title>
                <v-card-text>
                    <div style="width:100%; height:400px;">
                        <iframe 
                            width="100%" 
                            height="100%" 
                            frameborder="0" 
                            scrolling="no" 
                            marginheight="0" 
                            marginwidth="0"
                            :src="`https://maps.google.com/maps?q=${tempStore.lat},${tempStore.lng}&hl=es&z=14&amp;output=embed`"
                        >
                        </iframe>
                    </div>
                </v-card-text>
            </v-card>

            <v-card class="mb-5" v-if="tempStore && tempStore.id==0">
                <v-card-title>
                    Dirección del domicilio
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        label="Calles y referencias *"
                        outlined
                        v-model="address"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                        label="Nro."
                        outlined
                        v-model="number"
                        :dense="!$vuetify.breakpoint.smAndUp"
                    ></v-text-field>
                    <v-text-field
                        label="Nombre completo de la persona que recibirá el pedido *"
                        outlined
                        v-model="name"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                        label="Tel/Cel de contacto *"
                        outlined
                        v-model="phone"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required]"
                    ></v-text-field>
                </v-card-text>
            </v-card>

            <v-card v-if="tempStore" class="mb-5">
                <v-card-title>
                    Método de pago
                </v-card-title>
                <v-card-text>
                    <v-radio-group v-model="methodPayment">
                        <v-radio
                            label="Pago al contado"
                            value="cash_payment"
                        ></v-radio>
                        <v-alert
                            text
                            border="left"
                            class="ml-8"
                            v-if="methodPayment=='cash_payment'"
                        >
                            Paga en efectivo en el momento de la entrega.
                        </v-alert>
                        <v-radio
                            label="Depósito / transferencia bancaria"
                            value="bank_transfer"
                        ></v-radio>
                        <v-alert
                            text
                            border="left"
                            class="ml-8"
                            v-if="methodPayment=='bank_transfer'"
                        >
                            Realiza tu pago directamente en nuestra cuenta bancaria. Por favor, usa el número del pedido como referencia de pago. Tu pedido no se procesará hasta que se haya recibido el importe en nuestra cuenta.
                        </v-alert>
                        <!-- <v-radio
                            label="Pago online"
                            value="online_payment"
                            disabled
                        ></v-radio>
                        <v-alert
                            text
                            border="left"
                            class="ml-8"
                            v-if="methodPayment=='online_payment'"
                        >
                            Tenemos habilitados los siguientes metodos de pago totalmente online para mayor comodidad y seguridad en tus compras.
                        </v-alert> -->
                    </v-radio-group>
                </v-card-text>
            </v-card>

            <div v-if="tempStore" class="text-right">
                <i v-if="tempStore.id != 0">
                    {{ tempStore.name }}. Nos comunicaremos con usted cuando su pedido se encuentre listo para que pueda recogerlo de nuestra tienda. Muchas gracias por su compra.
                    <!-- Horarios de entrega: De lunes a sábado de 14:00 a 20:00 Hrs. (Domingos y feriados no habrá atención) -->
                </i>
                <i v-else>
                    Para el envió a domicilio recibirás un correo de confirmación indicando que tu pedido está listo y posteriormente una llamada de nuestro personal para coordinar la entrega.
                </i>
                <div class="pt-5"></div>
                <v-btn
                    color="primary"
                    large
                    rounded
                    type="submit"
                    :loading="loading"
                >
                    Realizar pedido
                </v-btn>
            </div>
        </v-form>

        <v-snackbar
            v-model="snackbar"
        >
            {{ msgSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import axios from 'axios'
import GoogleMap from '../../components/GoogleMap.vue'
export default {
    components: {
        GoogleMap
    },
    data() {
        return {
            loading: false,
            snackbar: false,
            msgSnackbar: '',
            itemStores: [],
            receiveOrder: '',
            tempStore: '',
            // map
            showMap: false,
            address: '',
            lat: null,
            lng: null,
            tmpLat: -19.589235,
            tmpLng: -65.753468,
            // payment
            name: '',
            phone: '',
            address: '',
            number: '',
            methodPayment: 'cash_payment',
            delivery: 0,
            // validation
            rules: {
                required: v => !!v || 'El campo es requerido',
                min: v => v.length >= 8 || 'Mínimo 8 caracteres',
                email: v => /.+@.+\..+/.test(v) || 'Correo electrónico inválido',
                phone: v => v.length >= 8 || 'Mínimo 8 dígitos',
                phone2: v => v.length <= 8 || 'Número inválido',
            },
            errors: {},
        }
    },
    props: ['token'],
    mounted() {
        this.getEcommerceSetting()
        this.getStores()
        this.getLocation()
    },
    created() {
        this.name = this.auth.name
        this.phone = this.auth.phone
    },
    methods: {
        async getEcommerceSetting() {
            await axios.post('/data-by-type/delivery', {}, {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response);
                if (response.data.length > 0) {
                    this.delivery = response.data[0].content
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 404 || error.response.status == 500) {
                    this.snackbar = true
                    this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                }
            })
        },
        async getStores() {
            await axios.post('get-stores', {}, {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                // console.log(response);
                response.data.forEach(element => {
                    this.itemStores.push({
                        id: element.id,
                        name: 'Recoger en '+element.name+' - '+element.address,
                        lat: element.lat_map,
                        lng: element.lng_map
                    })
                });
                this.itemStores.push({
                    id: 0,
                    name: 'Envío a domicilio'
                })
            })
            .catch((error) => {
                console.log(error);
            })
        },
        choseStore(event) {
            this.tempStore = this.itemStores.filter((item) => {return item.id===event})[0]
            // console.log(this.tempStore);
            if (this.tempStore.id != '') this.$emit('price-delivery', 0)
            else this.$emit('price-delivery', this.delivery)
        },
        /**
         * get location
         */
        getLocation() {
            window.navigator.geolocation.getCurrentPosition(
                (pos)=>{
                    var crd = pos.coords;
                    this.tmpLat = +crd.latitude
                    this.tmpLng = +crd.longitude
                    this.showMap = true
                },
                (err)=>{
                    console.warn('ERROR(' + err.code + '): ' + err.message)
                    this.showMap = true
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
        },
        resultClickMap(location) {
            // console.log(location);
            this.address = location.address
            this.lat = location.lat
            this.lng = location.lng
            // this.resultMap = location
        },

        /**
         * SEND ORDER
         */
        sendOrder() {
            if (this.$refs.refForm.validate()) {
                this.loading = true
                let formData = new FormData()
                formData.append('store', this.receiveOrder);
                formData.append('name', this.name);
                formData.append('phone', this.phone);
                formData.append('payment', this.methodPayment);
                formData.append('address', this.address);
                formData.append('lat', this.lat??'');
                formData.append('lng', this.lng??'');
                formData.append('cart', JSON.stringify(this.cart));
                formData.append('total', this.total);
                formData.append('delivery', (this.tempStore.id != '')?0:this.delivery);

                axios.post('send-order', formData, {
                    headers: {
                        Authorization: 'Bearer ' + this.token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    console.log(response)
                    this.$store.dispatch('cart/removeCart')
                    localStorage.removeItem('cart')
                    this.$router.push({name: 'orderReceived', params: {id: response.data}})

                    this.loading = false
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status == 404 || error.response.status == 500) {
                        this.snackbar = true
                        this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                    }

                    this.loading = false
                })
            } else {
                // notify
                this.snackbar = true
                this.msgSnackbar = 'Campos requeridos.'
                // notify END
            }
        }
    },
    computed: {
        cart () {
            return this.$store.state.cart.cart;
        },
        total () {
            return this.$store.state.cart.total;
        },
        auth () {
            return this.$store.state.auth;
        }
    },
}
</script>