<template>
    <div>
        <input
            id="pac-input"
            class="controls"
            type="text"
            placeholder="Buscar"
            :style="`border-bottom: 1px solid #000`"
        />
        <div id="map"></div>
    </div>
</template>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
export default {
    data() {
        return {
            apiKey: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg',
            map: null,
            markers: [],
            resultClick: {
                address: '',
                lat: null,
                lng: null
            }
        }
    },
    props: ['center', 'zoom'],
    async mounted() {
        const googleMapApi = await GoogleMapsApiLoader({
            libraries: ['places'],
            apiKey: this.apiKey
        })
        this.map = googleMapApi
        this.initMap()
    },
    methods: {
        initMap() {
            // const BOLIVIA_BOUNDS = {
            //     north: -19.527951,
            //     south: -19.611898,
            //     west: -19.574206,
            //     east: -19.567735
            // }
            this.map = new window.google.maps.Map(document.getElementById("map"), {
                zoom: this.zoom,
                center: this.center,
                streetViewControl: false,
                mapTypeControl: false,
                mapTypeId: "roadmap",
                // restriction: {
                //   latLngBounds: BOLIVIA_BOUNDS,
                //   strictBounds: true
                // },
            });

            // search box
            const input = document.getElementById("pac-input");
            const searchBox = new window.google.maps.places.SearchBox(input);
            this.map.addListener('bounds_changed', () => {
                searchBox.setBounds(this.map.getBounds())
            })
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces()
                if (places.length === 0) {
                return
                } // Clear out the old markers.
                this.markers.forEach(marker => {
                marker.setMap(null)
                })
                this.markers = [] // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds()
                places.forEach(place => {
                if (!place.geometry) {
                    console.log('Returned place contains no geometry')
                    return
                }
                this.markers.push(
                    new window.google.maps.Marker({
                    map: this.map,
                    title: place.name,
                    position: place.geometry.location
                    })
                )
                // this.getLatLng(this.markers[0].position)
                this.addMarker(this.markers[0].position)
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport)
                } else {
                    bounds.extend(place.geometry.location)
                }
                })
                this.map.fitBounds(bounds)
            })
            // end search box

            // Mapa básica
            // this.markers = new window.google.maps.Marker({
            //     position: { lat: -28.024, lng: 140.887 },
            //     map: this.map,
            // });
            this.map.addListener('click', event => {
                this.addMarker(event.latLng)
            })
            this.addMarker(this.center)
        },
        
        addMarker (location) {
            // console.log(location);
            this.deleteMarkers()
            const marker = new window.google.maps.Marker({
                position: location,
                // icon: 'url',
                map: this.map
            })
            this.markers.push(marker)
            this.getLatLng(location)
        }, // Sets the map on all markers in the array.
        deleteMarkers () {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null)
            }
            this.markers = []
            this.resultClick.address = ''
        },
        getLatLng (location) {
            // console.log(location);
            // geocoder
            // var myLatlngCurrent = new window.google.maps.LatLng(this.center.lat, this.center.lng)
            const geocoder = new window.google.maps.Geocoder()
            geocoder.geocode({ location: location }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        // console.log(results);
                        this.resultClick.address = results[0].formatted_address
                    } else {
                        alert('Sin resultados')
                    }
                } else {
                    alert('El geocodificador falló debido a: ' + status)
                }
            })
            // obtenemos lat y lng
            if (typeof(location.lat) === 'number') {
                this.resultClick.lat = location.lat
                this.resultClick.lng = location.lng
            } else {
                const tmpLocation = location.toString()
                // (-15.54, -65.3434)
                const tmpReplace = tmpLocation.replace('(', '').replace(')', '')
                const coord = tmpReplace.split(', ')
                this.resultClick.lat = parseFloat(coord[0])
                this.resultClick.lng = parseFloat(coord[1])
            }
            setTimeout(() => {
                this.$emit('result-click', this.resultClick)
            }, 1000);
        },
    },
}
</script>
<style lang="scss">
#map {
  height: 350px;
}
#pac-input {
  background-color: #fff;
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  text-overflow: ellipsis;
  width: 100%;
  border: 0 none;
  margin: 0;
  outline: 0 none;
}
</style>