<template>
    <div>
        <v-parallax
            src="../assets/images/slide1.jpg"
            :height="($vuetify.breakpoint.smAndUp)?250:180"
        >
            <v-container class="mt-auto">
                <v-row>
                    <v-col
                        cols="12"
                        v-if="auth.login"
                    >
                        <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
                            <v-icon :size="($vuetify.breakpoint.smAndUp)?65:45">
                                mdi-google-maps
                            </v-icon> <br>
                            Datos de entrega
                        </h1>
                    </v-col>
                    <v-col
                        cols="12"
                        v-else
                    >
                        <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
                            <v-icon :size="($vuetify.breakpoint.smAndUp)?65:45">
                                mdi-account
                            </v-icon> <br>
                            Iniciar sesión
                        </h1>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>

        <v-container class="py-15">
            <v-row v-if="JSON.stringify(cart)!='{}'">
                <v-col cols="12" md="4">
                    <v-card class="pb-8" v-sticky="{ isSticky: true }">
                        <v-card-title class="primary">
                            Resumen
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <p>Productos: <strong>{{ count }}</strong></p>
                            <p v-if="auth.login">Costo de envío: <strong>{{ (tempDelivery == 0)?'Sin costo':tempDelivery+' Bs.' }}</strong></p>
                            <h2 class="text-h5">
                                <strong>Total: </strong>
                                <strong>{{ (tempDelivery == 0)?total:total+(+tempDelivery) }} Bs.</strong>
                            </h2>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="8">

                    <!-- delivery data -->
                    <delivery-data
                        v-if="auth.login"
                        :token="auth.token"
                        @price-delivery="priceDelivery"
                    ></delivery-data>
                    <!-- delivery data END -->
                    <!-- <v-card v-if="auth.login">
                      <v-card-title>&nbsp;</v-card-title>
                      <v-card-text>
                        <delivery-data :token="auth.token"></delivery-data>
                      </v-card-text>
                      <v-card-actions class="py-10 px-5">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          large
                          rounded
                          class="pl-4"
                          to="/finalizar-compra"
                        >
                          Confirmar pedido
                          <v-icon
                            right
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card> -->

                    <v-card v-else>
                        <v-card-title>&nbsp;</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="8" offset-sm="2">
                                    <div class="mb-8">
                                      <v-btn
                                            block
                                            :x-large="$vuetify.breakpoint.smAndUp"
                                            outlined
                                            color="primary"
                                            @click="createAccount=!createAccount"
                                            v-if="!createAccount"
                                        >
                                            Crear cuenta
                                        </v-btn>
                                    </div>

                                    <div class="mb-8" v-if="createAccount">
                                        <!-- register -->
                                        <app-register @success-login="successfulLogin"></app-register>
                                        <!-- register END -->
                                        <div class="text-right pt-5">
                                            ¿Ya tienes una cuenta? <a href="javascript:;" @click="createAccount=!createAccount">Click aquí</a>
                                        </div>
                                    </div>
                                    <div class="mb-8" v-else>
                                        <!-- login -->
                                        <app-login></app-login>
                                        <!-- login END -->
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>

            <div class="pa-4 text-center" v-else>
                <div class="text-center mt-5 mb-10">
                    <v-icon color="grey darken-1" size="150">mdi-shopping-outline</v-icon>
                    <div class="mt-5 font-weight-thin">Tu carrito está vacío.</div>
                </div>
                <v-btn
                    color="error"
                    to="/tienda"
                >Seguir comprando</v-btn>
            </div>
        </v-container>
    </div>
</template>
<script>
const Sticky = {    
    bind(el, binding) {
        setStyle(el, binding.value);
    },
    componentUpdated(el, binding) {
        setStyle(el, binding.value);
    },
};

const setStyle = (el, values) => {
    const defaults = {
        isSticky: true,
        offset: 124,
    };
    
    const isSticky = values && values.isSticky !== undefined
        ? values.isSticky
        : defaults.isSticky;

    if (isSticky) {
        const offset = values && values.offset || defaults.offset;

        el.style.top = `${offset}px`;
        el.style.position = 'sticky';
        el.style.position = '-webkit-sticky'; /* Safari */
    } else {
        el.style.cssText = null;
    }
};
import AppLogin from '../views/auth/Login.vue'
import AppRegister from '../views/auth/Register.vue'
import DeliveryData from '../views/payment/DeliveryData.vue'
export default {
    components: {
        AppLogin,
        AppRegister,
        DeliveryData
    },
    directives: {
        Sticky,
    },
    data() {
        return {
            createAccount: false,
            tempDelivery: ''
        }
    },
    methods: {
        successfulLogin() {
            console.log('hello');
        },
        priceDelivery(resp) {
            this.tempDelivery = resp
        }
    },
    computed: {
        auth () {
            return this.$store.state.auth;
        },
        count () {
            return this.$store.state.cart.count;
        },
        cart () {
            return this.$store.state.cart.cart;
        },
        total () {
            return this.$store.state.cart.total;
        }
    },
}
</script>
<style lang="scss">
.sheet-cant {
    font-size: 18px;
    line-height: 28px;
}
</style>