var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-h5"},[_vm._v("Crear una cuenta nueva")]),_c('p',[_vm._v("Escribe tus datos en los campos debajo")]),_c('v-form',{ref:"refForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.formRegister.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Nombre(s) *","outlined":"","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required],"error":_vm.errors['name'] ? true :false,"error-messages":_vm.errors['name'] ? _vm.errors['name'][0] : ''},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Apellido(s) *","outlined":"","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required],"error":_vm.errors['last_name'] ? true :false,"error-messages":_vm.errors['last_name'] ? _vm.errors['last_name'][0] : ''},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('v-text-field',{attrs:{"label":"Dirección *","outlined":"","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required],"error":_vm.errors['address'] ? true :false,"error-messages":_vm.errors['address'] ? _vm.errors['address'][0] : ''},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('v-text-field',{attrs:{"label":"Celular *","outlined":"","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required, _vm.rules.phone],"error":_vm.errors['phone'] ? true :false,"error-messages":_vm.errors['phone'] ? _vm.errors['phone'][0] : ''},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-menu',{ref:"date1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de nacimiento *","outlined":"","persistent-hint":"","append-icon":"mdi-calendar","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required],"error":_vm.errors['birthday'] ? true :false,"error-messages":_vm.errors['birthday'] ? _vm.errors['birthday'][0] : ''},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-bo"},on:{"input":function($event){_vm.date1 = false}},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1),_c('v-text-field',{attrs:{"label":"Correo electrónico *","outlined":"","type":"email","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required, _vm.rules.email],"error":_vm.errors['email'] ? true :false,"error-messages":_vm.errors['email'] ? _vm.errors['email'][0] : ''},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"label":"Contraseña *","outlined":"","dense":!_vm.$vuetify.breakpoint.smAndUp,"rules":[_vm.rules.required, _vm.rules.min],"error":(_vm.errors['password']) ? true : false,"error-messages":(_vm.errors['password']) ? _vm.errors['password'][0] : ''},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Aceptas las "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/condiciones"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("Condiciones")])]}}])},[_vm._v(" Se abre en una ventana nueva ")]),_vm._v(" y la "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/politica-de-privacidad"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("Política de privacidad")])]}}])},[_vm._v(" Se abre en una ventana nueva ")]),_vm._v(". ")],1)]},proxy:true}]),model:{value:(_vm.accept),callback:function ($$v) {_vm.accept=$$v},expression:"accept"}}),_c('v-btn',{attrs:{"block":"","x-large":_vm.$vuetify.breakpoint.smAndUp,"color":"primary","type":"submit","loading":_vm.loading,"disabled":!_vm.accept}},[_vm._v(" Crear cuenta ")])],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msgSnackbar)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }