<template>
    <div>
        <h4 class="text-h5">Crear una cuenta nueva</h4>
        <p>Escribe tus datos en los campos debajo</p>
        <v-form @submit.prevent="formRegister" ref="refForm" lazy-validation>
            <v-text-field
                label="Nombre(s) *"
                outlined
                v-model="name"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required]"
                :error="errors['name'] ? true :false"
                :error-messages="errors['name'] ? errors['name'][0] : ''"
            ></v-text-field>

            <v-text-field
                label="Apellido(s) *"
                outlined
                v-model="lastname"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required]"
                :error="errors['last_name'] ? true :false"
                :error-messages="errors['last_name'] ? errors['last_name'][0] : ''"
            ></v-text-field>

            <v-text-field
                label="Dirección *"
                outlined
                v-model="address"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required]"
                :error="errors['address'] ? true :false"
                :error-messages="errors['address'] ? errors['address'][0] : ''"
            ></v-text-field>

            <v-text-field
                label="Celular *"
                outlined
                v-model="phone"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required, rules.phone]"
                :error="errors['phone'] ? true :false"
                :error-messages="errors['phone'] ? errors['phone'][0] : ''"
            ></v-text-field>

            <v-menu
                ref="date1"
                v-model="date1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="birthday"
                        label="Fecha de nacimiento *"
                        outlined
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :dense="!$vuetify.breakpoint.smAndUp"
                        :rules="[rules.required]"
                        :error="errors['birthday'] ? true :false"
                        :error-messages="errors['birthday'] ? errors['birthday'][0] : ''"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="birthday"
                    no-title
                    @input="date1 = false"
                    locale="es-bo"
                ></v-date-picker>
            </v-menu>

            <v-text-field
                label="Correo electrónico *"
                outlined
                type="email"
                v-model="email"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required, rules.email]"
                :error="errors['email'] ? true :false"
                :error-messages="errors['email'] ? errors['email'][0] : ''"
            ></v-text-field>

            <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Contraseña *"
                outlined
                @click:append="show = !show"
                v-model="password"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required, rules.min]"
                :error="(errors['password']) ? true : false"
                :error-messages="(errors['password']) ? errors['password'][0] : ''"
            ></v-text-field>

            <v-checkbox v-model="accept">
                <template v-slot:label>
                    <div>
                    Aceptas las
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <a
                            target="_blank"
                            href="/condiciones"
                            @click.stop
                            v-on="on"
                        >Condiciones</a>
                        </template>
                        Se abre en una ventana nueva
                    </v-tooltip>
                    y la
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <a
                            target="_blank"
                            href="/politica-de-privacidad"
                            @click.stop
                            v-on="on"
                        >Política de privacidad</a>
                        </template>
                        Se abre en una ventana nueva
                    </v-tooltip>.
                    </div>
                </template>
            </v-checkbox>
            
            <v-btn
                block
                :x-large="$vuetify.breakpoint.smAndUp"
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="!accept"
            >
                Crear cuenta
            </v-btn>
        </v-form>

        <v-snackbar
            v-model="snackbar"
        >
            {{ msgSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            snackbar: false,
            msgSnackbar: '',
            loading: false,
            show: false,
            date1: false,
            // datas
            name: '',
            lastname: '',
            address: '',
            phone: '',
            birthday: '',
            email: '',
            password: '',
            accept: false,
            // validation
            rules: {
                required: v => !!v || 'El campo es requerido',
                min: v => v.length >= 8 || 'Mínimo 8 caracteres',
                email: v => /.+@.+\..+/.test(v) || 'Correo electrónico inválido',
                phone: v => (v.length >= 8 && v.length <= 8) || 'Debe contener 8 dígitos',
            },
            errors: {},
        }
    },
    methods: {
        async formRegister() {
            if (this.$refs.refForm.validate()) {
                this.loading = true
                this.errors = {}
                const formData = new FormData()
                formData.append('name', this.name)
                formData.append('last_name', this.lastname)
                formData.append('address', this.address)
                formData.append('phone', this.phone)
                formData.append('birthday', this.birthday)
                formData.append('email', this.email)
                formData.append('password', this.password)

                await axios.post(`register`, formData)
                .then(response => {
                    console.log(response);
                    if (response.data.msg == 'ok') {
                        // notify
                        this.snackbar = true
                        this.msgSnackbar = 'Registro exitoso.'
                        // notify END
                        localStorage.setItem('authenticate', JSON.stringify({
                            login:true,
                            token:response.data.access_token,
                            name:response.data.name,
                            phone:response.data.phone,
                        }))
                        this.$store.commit('auth/addTokenLogin', {
                            token: response.data.access_token,
                            login: true,
                            name: response.data.name,
                            phone: response.data.phone,
                        })
                        this.$emit('success-login')
                    }
                    this.loading = false
                })
                .catch(error => {
                    if (error.response.status == 404 || error.response.status == 500) {
                        this.snackbar = true
                        this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                    }
                    this.errors = error.response.data.errors
                    this.loading = false
                })
            }
        }
    },
}
</script>