<template>
    <div>
        <h4 class="text-h5">Ingresa usando tu email</h4>
        <p>Escribe tus datos en los campos debajo</p>
        <v-form @submit.prevent="formLogin" ref="refForm" lazy-validation>
            <v-text-field
                label="Correo electrónico *"
                outlined
                type="email"
                v-model="email"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required, rules.email]"
                :error="errors['email'] ? true :false"
                :error-messages="errors['email'] ? errors['email'][0] : ''"
            ></v-text-field>
            
            <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Contraseña *"
                outlined
                @click:append="show = !show"
                v-model="password"
                :dense="!$vuetify.breakpoint.smAndUp"
                :rules="[rules.required]"
                :error="(errors['password']) ? true : false"
                :error-messages="(errors['password']) ? errors['password'][0] : ''"
            ></v-text-field>

            <v-btn
                block
                :x-large="$vuetify.breakpoint.smAndUp"
                color="primary"
                type="submit"
                :loading="loading"
            >
                Iniciar sesión
            </v-btn>
        </v-form>

        <v-snackbar
            v-model="snackbar"
        >
            {{ msgSnackbar }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            snackbar: false,
            msgSnackbar: '',
            loading: false,
            show: false,
            email: '',
            password: '',
            // validation
            rules: {
                required: v => !!v || 'El campo es requerido',
                min: v => v.length >= 8 || 'Mínimo 8 caracteres',
                email: v => /.+@.+\..+/.test(v) || 'Correo electrónico inválido'
            },
            errors: {},
        }
    },
    methods: {
        async formLogin() {
            if (this.$refs.refForm.validate()) {
                this.loading = true
                this.errors = {}
                const formData = new FormData()
                formData.append('email', this.email)
                formData.append('password', this.password)
    
                await axios.post(`login`, formData)
                .then(response => {
                    console.log(response);
                    if (response.data.msg == 'ok') {
                        // notify
                        this.snackbar = true
                        this.msgSnackbar = 'Inicio de sesión exitoso.'
                        // notify END
                        localStorage.setItem('authenticate', JSON.stringify({
                            login: true,
                            token: response.data.access_token,
                            name: response.data.name,
                            phone: response.data.phone
                        }))
                        this.$store.commit('auth/addTokenLogin', {
                            token: response.data.access_token,
                            login: true,
                            name: response.data.name,
                            phone: response.data.phone
                        })
                        this.$emit('success-login')
                    }
                    this.loading = false
                })
                .catch(error => {
                    if (error.response.status == 404 || error.response.status == 500) {
                        this.snackbar = true
                        this.msgSnackbar = 'Error con el servidor, por favor vuelva a intentarlo más tarde.'
                    }
                    if (error.response.status == 401) {
                        this.snackbar = true
                        this.msgSnackbar = 'Lo sentimos, no pudimos encontrar tu cuenta.'
                    }
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors
                    }
                    this.loading = false
                })
            }
        }
    },
}
</script>